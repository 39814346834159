(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/hero/assets/javascripts/hero.js') >= 0) return;  svs.modules.push('/components/marketplace/hero/assets/javascripts/hero.js');

'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  clx
} = svs.components.lbUtils;
const {
  Image
} = svs.content.image_v2;

const Hero = _ref => {
  let {
    children,
    className,
    height,
    horizontal,
    image,
    vertical
  } = _ref;
  const imageOptions = _objectSpread(_objectSpread({}, image), {}, {
    title: 'Hero image',
    alt: 'Hero image'
  });
  return React.createElement("div", {
    className: clx("marketplace-hero", ['left', 'center', 'right'].includes(horizontal) && "marketplace-hero-".concat(horizontal), ['top', 'middle', 'bottom'].includes(vertical) && "marketplace-hero-".concat(vertical), className),
    "data-testid": "qa-marketplace-hero",
    style: _objectSpread({}, !isNaN(height) && {
      height: "".concat(height, "px")
    })
  }, imageOptions && React.createElement(Image, {
    cssSelectors: "marketplace-hero-background-image",
    image: imageOptions
  }), React.createElement("div", {
    className: "marketplace-hero-content",
    "data-testid": "qa-marketplace-hero-content"
  }, children));
};
setGlobal('svs.components.marketplace.hero.Hero', Hero);

 })(window);